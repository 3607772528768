import React from 'react'

const TopAgentList = React.lazy(() => import('../pages/TopAgents/TopAgentsList/TopAgentsList'))
const CreateYearlyTopAgent = React.lazy(() =>
  import('../pages/TopAgents/CreateYearlyTopAgent/CreateYearlyTopAgent'),
)
const EditYearlyTopAgent = React.lazy(() =>
  import('../pages/TopAgents/EditYearlyTopAgent/EditYearlyTopAgent'),
)
const MonthlyTopAgentList = React.lazy(() =>
  import('../pages/TopAgents/MonthlyTopAgentsList/MonthlyTopAgentsList'),
)
const CreateMonthlyTopAgent = React.lazy(() =>
  import('../pages/TopAgents/CreateMonthlyTopAgent/CreateMonthlyTopAgent'),
)
const EditMonthlyTopAgent = React.lazy(() =>
  import('../pages/TopAgents/EditMonthlyTopAgent/EditMonthlyTopAgent'),
)

const GlobalTopAgentsList = React.lazy(() =>
  import('../pages/TopAgents/GlobalTopAgentsList/GlobalTopAgentsList'),
)
const CreateGlobalTopAgent = React.lazy(() =>
  import('../pages/TopAgents/CreateGlobalTopAgent/CreateGlobalTopAgent'),
)
const EditGloballyTopAgent = React.lazy(() =>
  import('../pages/TopAgents/EditGlobalTopAgent/EditGlobalTopAgent'),
)

const UnicornAgentList = React.lazy(() =>
  import('../pages/TopAgents/UnicornAgent/UnicornAgentList'),
)

export {
  CreateYearlyTopAgent,
  TopAgentList,
  EditYearlyTopAgent,
  CreateMonthlyTopAgent,
  MonthlyTopAgentList,
  EditMonthlyTopAgent,
  GlobalTopAgentsList,
  CreateGlobalTopAgent,
  EditGloballyTopAgent,
  UnicornAgentList,
}
