import React from 'react'
import PropTypes from 'prop-types'
import { CCard, CCardBody, CTable, CTableBody, CTableRow } from '@coreui/react'
import TableHeader from 'src/components/Common/Table/TableHeader'
import ResultNotFound from 'src/components/ResultNotFound'
import TableLoading from 'src/components/TableLoading'
import Pagination from 'src/components/Pagination/Pagination'
import { YearEndFrameTableRow } from './YearEndFrameTableRow'

export const YearEndFrameTable = (props) => {
  const {
    paginateIndex,
    totalCount,
    totalPage,
    currentPage,
    bonusFrames,
    handlePage,
    handleBonusFrame,
    loading,
    openModal,
  } = props

  const tableHeaders = [
    {
      name: '#',
      className: 'text-center',
    },
    {
      name: 'Image',
    },
    {
      name: 'Group Name',
      className: 'text-center',
    },
    {
      name: 'All Products Frame',
      className: 'text-center',
    },
    {
      name: 'Bonus Period',
      className: 'text-center',
    },
    {
      name: 'Image Type',
      className: 'text-center',
    },
    {
      name: 'Category',
      className: 'text-center',
    },
    {
      name: 'Region',
      className: 'text-center',
    },
    {
      name: 'Action',
      className: 'text-center',
    },
  ]
  return (
    <CCard>
      <CCardBody>
        <CTable hover responsive>
          <TableHeader list={tableHeaders} />
          <CTableBody>
            {!loading &&
              (bonusFrames?.length > 0 ? (
                bonusFrames?.map((bonusFrame, index) => (
                  <YearEndFrameTableRow
                    index={index}
                    key={index}
                    paginateIndex={paginateIndex}
                    bonusFrame={bonusFrame}
                    handleBonusFrame={handleBonusFrame}
                    openModal={openModal}
                  />
                ))
              ) : (
                <ResultNotFound
                  colspan={tableHeaders?.length}
                  text="Year End Bonus Frames"
                  show={true}
                />
              ))}
          </CTableBody>
          {loading && <TableLoading colspan={tableHeaders?.length} />}
        </CTable>
        <nav className="col-12 d-flex justify-content-end mt-4">
          <Pagination
            totalCount={totalCount}
            pageSize={totalPage}
            currentPage={currentPage}
            onPageChange={(page) => handlePage(page)}
          />
        </nav>
      </CCardBody>
    </CCard>
  )
}

YearEndFrameTable.propTypes = {
  paginateIndex: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  totalPage: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  bonusFrames: PropTypes.array.isRequired,
  handlePage: PropTypes.func.isRequired,
  handleBonusFrame: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  openModal: PropTypes.func.isRequired,
}
