const agents = {
  agents: '/agents/list',
  agentDetail: '/admin/agents/detail',
  searchAgent: '/agents/search',
  createAgent: '/admin/agents/create',
  updateAgent: '/admin/agents/update',
  approveAgent: '/admin/agents/approve',
  updateAgentStatus: '/admin/agents/update-status',
  resetPassword: '/admin/agents/reset-password',
  updatePromptStatus: '/admin/agents/prompt-password/change-status',
  uploadProfile: '/agents/upload-profile',
  downloadProfile: '/admin/agents/download-card',
  generateCard: '/agents/cards',
  deleteProfile: '/agents/profile-image/delete',
  dashboardAgentPost: '/admin/agents/posts/list',
  agentPost: '/posts/detail',
  downloadExcel: '/admin/agents/download-excel',
  uploadExcelFile: '/admin/agents/import-agents-excel',
  downloadAgentsExcel: '/agents/agent_by_product/excel',
  saleRegions: '/agents/sales-region/list',
  saleRegionDetail: '/agents/sales-region/detail',
  createSaleRegion: '/agents/sales-region/create',
  updateSaleRegion: '/agents/sales-region/update',
  countries: '/agents/countries/list',
  township: '/agents/township/list',
  mainAgents: '/main-agents/list',
  childAgentList: '/agents/childs/list',
  allAgents: '/agents/all',
  agentHistoryList: 'agents/histories/list',
  generateAgentReport: '/admin/agents/monthly-reports/generate',
  importBonusStock: '/admin/agents/bonus-stock/import',
  agentStockTypeList: '/agents/stock-types/list',
}

export default agents
