const TopAgent = {
  YearlyTopAgentList: '/agents/top-agents/list',
  createYearlyTopAgent: '/agents/top-agents/create',
  YearlyTopAgentDetail: '/agents/top-agents/detail',
  EditYearlyTopAgent: '/agents/top-agents/update',
  DeleteYearlyTopAgent: '/agents/top-agents/delete',
  MonthlyTopAgentList: '/agents/monthly-top-agents/list',
  createMonthlyTopAgent: '/agents/monthly-top-agents/create',
  MonthlyTopAgentDetail: '/agents/monthly-top-agents/detail',
  EditMonthlyTopAgent: '/agents/monthly-top-agents/update',
  DeleteMonthlyTopAgent: '/agents/monthly-top-agents/delete',
  GloballyTopAgentList: '/agents/global-top-agents/list',
  createGloballyTopAgent: '/agents/global-top-agents/create',
  GloballyTopAgentDetail: '/agents/global-top-agents/detail',
  EditGloballyTopAgent: '/agents/global-top-agents/update',
  DeleteGloballyTopAgent: '/agents/global-top-agents/delete',
  ImportMonthlyTopAgent: '/admin/agents/monthly-top-agents/import',
  ImportYearlyTopAgent: '/admin/agents/yearly-top-agents/import',
  ImportGlobalTopAgent: '/admin/agents/global-top-agents/import',
  ImportReportTopAgent: '/admin/agents/report/import-top-agents',
  TopAgentSort: '/admin/top-agents/sort',
  MonthlyTopAgentExport: '/admin/monthly-top-agents/download-excel',
  MonthlyTopAgentDetailExport: '/admin/monthly-top-agents/details/download-excel',
  MonthlyTopAgentSort: '/admin/monthly-top-agents/sort',
  MonthlyTopAgentImport: '/admin/monthly-top-agents/import',
  UnicornTopAgentList: '/agents/unicorn-agents/list',
  UnicornTopAgentSort: '/admin/unicorn-agents/sort',
  ImportUnicornAgent: '/admin/unicorn-agents/import',
  DeleteUnicornAgent: '/admin/unicorn-agents/delete',
}

export default TopAgent
