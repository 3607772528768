import React from 'react'
import { useSelector } from 'react-redux'
import { Routes, Route, Navigate } from 'react-router-dom'
import { Login } from '../routes/auth'
import { NotFound, Maintenance, Error, NetworkError, Unauthorized } from '../routes/error'
import { Dashboard, Layout } from '../routes/layout'
import { BrandList, BrandDetail, CreateBrand, EditBrand } from '../routes/brand'
import {
  AdvertisementList,
  AdvertisementDetail,
  CreateAdvertisement,
  EditAdvertisement,
} from '../routes/advertisement'
import {
  AgentList,
  AgentDetail,
  AgentDetailPost,
  CreateAgent,
  CreateAgentRegion,
  EditAgentRegion,
  EditAgent,
  CreateAgentProduct,
  EditAgentProduct,
  GenerateAgentReport,
  BonusStock,
} from '../routes/agent'
import {
  PromotionList,
  PromotionDetail,
  CreatePromotion,
  EditPromotion,
  MonthlyPromotionList,
  CreateMonthlyPromotion,
  MonthlyPromotionDetail,
  EditMonthlyPromotion,
  FocPromotionList,
  CreateFocPromotion,
  FocPromotionDetail,
  EditFocPromotion,
  DiscountPromotionList,
  CreateDiscountPromotion,
  DiscountPromotionDetail,
  EditDiscountPromotion,
  DoublePromotionList,
  DoublePromotionDetail,
  CreateDoublePromotion,
  EditDoublePromotion,
} from '../routes/promotion'
import {
  GiftList,
  GiftDetail,
  CreateGift,
  EditGift,
  CreateEndUserGift,
  EditEndUserGift,
  EndUserGiftList,
  EndUserGiftDetail,
} from '../routes/gift'
import {
  MemberTypeList,
  MemberTypeDetail,
  CreateMemberType,
  EditMemberType,
} from '../routes/member-types'
import { TagList, TagDetail, CreateTag, EditTag } from '../routes/tag'
import { AttributeList, AttributeDetail, CreateAttribute, EditAttribute } from '../routes/attribute'
import {
  TopAgentList,
  CreateYearlyTopAgent,
  EditYearlyTopAgent,
  MonthlyTopAgentList,
  CreateMonthlyTopAgent,
  EditMonthlyTopAgent,
  GlobalTopAgentsList,
  CreateGlobalTopAgent,
  EditGloballyTopAgent,
  UnicornAgentList,
} from './top-agent'
import { PostList, PostDetail, CreatePost, EditPost } from '../routes/post'
import { RegistrationList, CreateRegistration } from '../routes/registration'
import {
  NationalIdList,
  NationalIdDetail,
  CreateNationalId,
  EditNationalId,
} from '../routes/national-id'
import {
  ProductList,
  ProductDetail,
  CreateProduct,
  EditProduct,
  ProductAttributeList,
  ProductAttributeDetail,
  CreateProductAttribute,
  EditProductAttribute,
  ProductReviewList,
  ProductReviewDetail,
  CreateProductReview,
  EditProductReview,
  ProductPostList,
  ProductPostDetail,
  CreateProductPost,
  EditProductPost,
  ProductSalePromotionList,
  CreateProductSalePromotion,
  EditProductSalePromotion,
  ProductMonthlyPromotion,
  CreateProductMonthlyPromotion,
  EditProductMonthlyPromotion,
  ProductFOCPromotion,
  CreateProductFOC,
  EditProductFOCPromotion,
  ProductDiscountPromotion,
  CreateProductDiscount,
  EditProductDiscount,
  ProductMonthlyBonusRule,
  CreateProductMonthlyBonusRule,
  EditProductMonthlyBonusRule,
  ProductFlyerList,
  ProductFlyerDetail,
  ProductCatalogueList,
  CreateProductCatalogue,
  EditProductCatalogue,
  ProductCatalogueDetail,
} from '../routes/product'
import {
  MonthlyPurchaseReportList,
  MonthlyPurchaseReportDetail,
  MonthlyPurchaseReportAgentList,
  MonthlyPurchaseReportDirectSaleList,
  MonthlyPurchaseReportEndUserList,
  YearlyPurchaseReportList,
  MonthlyPurchaseReportEndUserDetail,
} from '../routes/agent-report'
import {
  CreateTransaction,
  TransactionList,
  TransactionDetail,
  SmartSaleTransactionList,
  EndUsersTransactionList,
  OverallTransactionList,
  DesignatedAgentsTransactionList,
  EndUserGiftVoucherList,
} from '../routes/transaction'
import { InventoryList, InventoryHistoryList, CreateInventory } from './inventory'
import {
  CurrencyList,
  CreateCurrency,
  CurrencyDetail,
  EditCurrency,
  CurrencyHistoryList,
} from '../routes/currency'
import {
  CurrencyRegionList,
  CreateCurrencyRegion,
  CurrencyRegionDetail,
  EditCurrencyRegion,
} from '../routes/currency-region'
import {
  BonusFrameList,
  CreateBonusFrame,
  CreateProductFrame,
  BonusFrameDetail,
  EditBonusFrame,
  CreateYearEndFrame,
  EditYearEndFrame,
} from './bonus-frame'
import { BonusGroupList, CreateBonusGroup, EditBonusGroup, BonusGroupDetail } from './bonus-groups'
import {
  AllBonusAwardList,
  AllBonusAwardDetail,
  ProductBonusAwardList,
} from '../routes/bonus-awards'
import { ChangePassword } from '../routes/change-password'
import { default as AgentProductList } from '../pages/Agents/AgentDetail/ProductList/ProductList'
import { default as AgentRegionList } from '../pages/Agents/AgentDetail/RegionList/RegionList'
import { default as AgentGiftList } from '../pages/Agents/AgentDetail/GiftList/GiftList'
import { default as AgentPostList } from '../pages/Agents/AgentDetail/PostList/PostList'
import { default as AgentBonusProfile } from '../pages/Agents/AgentDetail/BonusProfile/BonusProfile'
import { default as AgentProductProfile } from '../pages/Agents/AgentDetail/ProductProfile/ProductProfile'
import { CreateUser, UserList, UserDetail, EditUser } from './user'
import {
  CreateMonthlyBonusRule,
  BonusRuleListPage,
  MonthlyBonusRuleDetail,
  EditMonthlyBonusRule,
  CreateThreeMonthsBonusRule,
  EditThreeMonthsBonusRule,
  ThreeMonthsBonusRuleDetail,
  CreateYearEndRule,
  YearEndRuleDetail,
  EditYearEndRule,
} from './bonus-rules'
import { TotalBonusAwardList } from './total-bonus-awards'
import {
  CreateGroup,
  EditGroup,
  GroupList,
  GroupDetail,
  TriBonusRulesList,
  CreateTriBonusRules,
  EditTriBonusRules,
} from './product-groups'

import {
  TripList,
  TripDetail,
  TripProductGroup,
  TripReportList,
  TripReportListDetail,
  TripReportProductGroup,
  TripReportProductGroupDetail,
  SixMonthTripList,
  SixMonthTripDetail,
} from './trip'

import { BonusPrizeList, BonusPrizeDetail, CreateBonusPrize, EditBonusPrize } from './bonus-prize'
import { NotificationList, CreateNotification, NotificationDetail } from './notification'
import { SurveyResponse, SurveyList, CreateSurvey } from './survey'
import { SixMonthsBonusList, SixMonthsBonusDetail } from './six-months-bonus'
import { AgentHistory, StockHistory, HistoryList } from './history'
import { YearEndReportList, YearEndReportDetail, YearEndReportGroup } from './year-end-report'
import { YearEndAwardList, YearEndAwardDetail, YearEndAwardProductGroup } from './year-end-award'
import {
  BirthdayFrameList,
  EditBirthdayFrame,
  CreateBirthdayFrame,
  BirthdayFrameDetail,
  BirthdayAgentList,
} from './birthday-frame'
import { QRCodeFileList, CreateQRCodeFile } from './qr-code-file'
import {
  CreatePromotionGroup,
  PromotionGroupList,
  PromotionGroupDetail,
  EditPromotionGroup,
} from './promotion-group'
import {
  InvoiceThemeList,
  InvoiceThemeDetail,
  CreateInvoiceTheme,
  EditInvoiceTheme,
} from './invoice-themes'
import {
  AgentLevelAgentList,
  AgentLevelList,
  EditAgentLevel,
  AgentLevelDetail,
  CreateAgentLevel,
} from './agent-level'
import { YearEndTripGroup } from './year-end-trip'
import { YearEndFrameList } from 'src/pages/BonusFrames/FrameList/YearEndFrameList'
import { ThreeMonthFrameList } from 'src/pages/BonusFrames/FrameList/ThreeMonthFrameList'
import { SixMonthFrameList } from 'src/pages/BonusFrames/FrameList/SixMonthFrameList'
import {
  CreateYearEndGroupAward,
  EditYearEndGroupAward,
  YearEndGroupAwardDetail,
  YearEndGroupAwardList,
} from './year-end-group-awards'

import {
  YearEndBonusAwardList,
  YearEndBonusAwardDetail,
  YearEndBonusAwardChildList,
  YearEndBonusAwardProductGroup,
} from './year-end-bonus-award'

import {
  BonusProfileList,
  BonusProfileUpload,
  CreateBonusProfile,
  ProductProfileList,
  CreateProductProfile,
  ProductProfileUpload,
} from './bonus-profile'
import Authorization from 'src/components/Routes/Authorization'
import { CustomerList, CustomerDetail } from './customer'
import { PrizeWinnerList } from './prize-winners'
import { PrizeList, PrizeDetail, PrizeCreate, PrizeEdit } from './prize'
import { EventList, EventCreate, EventDetail, EventEdit } from './events'

const Router = (props) => {
  const auth = useSelector((state) => state.auth)

  //Logout when token is expired
  const expiredTime = auth.expired_at
  // console.log('current time ', Date.now(), expiredTime * 1000)

  if (expiredTime * 1000 < Date.now()) {
    // console.log('expired ')
    localStorage.removeItem('user')
  }

  return (
    <>
      <Routes>
        <Route path="/network-error" name="Network Error" element={<NetworkError />} />
        <Route path="/error" name="Page 500" element={<Error />} />
        <Route path="/maintenance" name="Maintenance" element={<Maintenance />} />
        <Route path="/unauthorized" name="Maintenance" element={<Unauthorized />} />

        {!auth.isLoggedIn ? (
          <>
            <Route path="/login" name="Login Page" element={<Login />} />
            <Route path="*" name="Login Page" element={<Navigate to="/login" />} />
          </>
        ) : (
          <>
            <Route path="*" element={<NotFound />} />
            <Route path="/not-found" element={<NotFound />} />
            <Route path="/" element={<Navigate replace to="/dashboard" />} />
            <Route path="/login" element={<Navigate to="/dashboard" replace />} />

            <Route path="/" element={<Layout />}>
              <Route path="/dashboard" name="Home" element={<Dashboard />} />
              <Route path="/change-password" name="Change Password" element={<ChangePassword />} />
              {/* brands */}
              <Route path="/brands" name="Brand List" element={<BrandList />} />
              <Route
                path="/brands/:id"
                name="Brand Detail"
                element={<BrandDetail authed={true} />}
              />
              <Route path="/brands/create" name="Create Brand" element={<CreateBrand />} />
              <Route path="/brands/:id/edit" name="Edit Brand" element={<EditBrand />} />
              {/* advertisements */}
              <Route
                path="/advertisements"
                name="Advertisement List"
                element={<AdvertisementList />}
              />
              <Route
                path="/advertisements/:id"
                name="Advertisement Detail"
                element={<AdvertisementDetail authed={true} />}
              />
              <Route
                path="/advertisements/create"
                name="Create Advertisement"
                element={<CreateAdvertisement authed={true} />}
              />
              <Route
                path="/advertisements/:id/edit"
                name="Edit Advertisement"
                element={<EditAdvertisement authed={true} />}
              />
              {/* agent purchase monthly report routes  */}
              <Route
                path="/monthly-agents-reports"
                name="Monthly Agent Report List"
                element={<MonthlyPurchaseReportList />}
              />
              <Route
                path="/monthly-agents-reports/:reportId"
                name="Monthly Agent Report Detail"
                element={<MonthlyPurchaseReportDetail />}
              />
              <Route
                path="/monthly-agents-reports/:reportId/agents"
                name="Monthly Agent Report Agent List"
                element={<MonthlyPurchaseReportAgentList />}
              />
              <Route
                path="/monthly-agents-reports/:reportId/direct-sales"
                name="Monthly Purchase Report Direct Sale List"
                element={<MonthlyPurchaseReportDirectSaleList />}
              />
              <Route
                path="/monthly-agents-reports/:reportId/end-users"
                name="Monthly Purchase Report End User List"
                element={<MonthlyPurchaseReportEndUserList />}
              />
              <Route
                path="/monthly-agents-reports/:reportId/end-user"
                name="Monthly Purchase Report End User List"
                element={<MonthlyPurchaseReportEndUserDetail />}
              />
              <Route
                path="/agent-product-reports"
                name="Monthly Agent Product Report List"
                element={<YearlyPurchaseReportList />}
              />
              {/* transaction routes  */}
              <Route path="/transactions" name="Transaction List" element={<TransactionList />} />
              <Route
                path="/transactions/create"
                name="Create Transaction"
                element={<CreateTransaction />}
              />
              <Route
                // path="/transactions/agents/:agentId/vouchers/:voucherNo"
                path="/transactions/:saleId"
                name="Transaction Detail"
                element={<TransactionDetail authed={true} />}
              />
              <Route
                path="/smart-sale/transactions"
                name="Smart Sale Transaction List"
                element={<SmartSaleTransactionList />}
              />
              <Route
                path="/end-users/transactions"
                name="Transaction List"
                element={<EndUsersTransactionList />}
              />
              <Route
                path="/prospective-agents/transactions"
                name="New Agents"
                element={<DesignatedAgentsTransactionList />}
              />
              <Route
                path="/overall/transactions"
                name="Overall Transaction List"
                element={<OverallTransactionList />}
              />
              <Route
                path="/end-users/gift-vouchers"
                name="End User Gift Vouchers"
                element={<EndUserGiftVoucherList />}
              />
              {/* customer routes */}
              <Route path="/customers" name="Customer List" element={<CustomerList />} />
              <Route
                path="/customers/:customerNo"
                name="Customer Detail"
                element={<CustomerDetail />}
              />
              {/* post routes  */}
              <Route path="/posts" name="Post List" element={<PostList />} />
              <Route path="/posts/:id" name="Post Detail" element={<PostDetail authed={true} />} />
              <Route path="/posts/create" name="Create Post" element={<CreatePost />} />
              <Route path="/posts/:id/edit" name="Edit Post" element={<EditPost />} />
              {/* tags  */}
              <Route path="/tags" name="Tag List" element={<TagList />} />
              <Route path="/tags/:id" name="Tag Detail" element={<TagDetail authed={true} />} />
              <Route path="/tags/create" name="Create Tag" element={<CreateTag />} />
              <Route path="/tags/:id/edit" name="Edit Tag" element={<EditTag />} />
              {/* attributes  */}
              <Route path="/attributes" name="Attribute List" element={<AttributeList />} />
              <Route
                path="/attributes/:id"
                name="Attribute Detail"
                element={<AttributeDetail authed={true} />}
              />
              <Route
                path="/attributes/create"
                name="Create Attribute"
                element={<CreateAttribute />}
              />
              <Route
                path="/attributes/:id/edit"
                name="Edit Attribute"
                element={<EditAttribute />}
              />
              {/* promotions  */}
              <Route path="/promotions" name="Promotion List" element={<PromotionList />} />
              <Route
                path="/promotions/:id/products/:productId"
                name="Promotion Detail"
                element={<PromotionDetail authed={true} />}
              />
              <Route
                path="/promotions/:productId/create"
                name="Create Promotion"
                element={
                  <Authorization>
                    <CreatePromotion />
                  </Authorization>
                }
              />
              <Route
                path="/promotions/:id/products/:productId/edit"
                name="Edit Promotion"
                element={
                  <Authorization>
                    <EditPromotion />
                  </Authorization>
                }
              />
              {/* monthly promotions  */}
              <Route
                path="/monthly-promotion-group"
                name="Monthly Promotion List"
                element={<MonthlyPromotionList />}
              />
              <Route
                path="/monthly-promotion-group/:id"
                name="Monthly Promotion Detail"
                element={<MonthlyPromotionDetail authed={true} />}
              />
              <Route
                path="/monthly-promotion-group/create"
                name="Create Monthly Promotion"
                element={
                  <Authorization>
                    <CreateMonthlyPromotion />
                  </Authorization>
                }
              />
              <Route
                path="/monthly-promotion-group/edit/:id"
                name="Edit Monthly Promotion"
                element={
                  <Authorization>
                    <EditMonthlyPromotion />
                  </Authorization>
                }
              />
              {/* foc promotions  */}
              <Route
                path="/foc-promotions"
                name="Foc Promotion List"
                element={<FocPromotionList />}
              />
              <Route
                path="/foc-promotions/:id"
                name="Foc Promotion Detail"
                element={<FocPromotionDetail authed={true} />}
              />
              <Route
                path="/foc-promotions/create"
                name="Create Foc Promotion"
                element={
                  <Authorization>
                    <CreateFocPromotion />
                  </Authorization>
                }
              />
              <Route
                path="/foc-promotions/edit/:id"
                name="Edit Foc Promotion"
                element={
                  <Authorization>
                    <EditFocPromotion />
                  </Authorization>
                }
              />
              {/* discount promotions  */}
              <Route
                path="/discount-promotions"
                name="Discount Promotion List"
                element={<DiscountPromotionList />}
              />
              <Route
                path="/discount-promotions/:id"
                name="Discount Promotion Detail"
                element={<DiscountPromotionDetail authed={true} />}
              />
              <Route
                path="/discount-promotions/create"
                name="Create Discount Promotion"
                element={
                  <Authorization>
                    <CreateDiscountPromotion />
                  </Authorization>
                }
              />
              <Route
                path="/discount-promotions/edit/:id"
                name="Edit Discount Promotion"
                element={
                  <Authorization>
                    <EditDiscountPromotion />
                  </Authorization>
                }
              />
              {/* double promotions  */}
              <Route
                path="/double-promotions"
                name="Double Promotion List"
                element={<DoublePromotionList />}
              />
              <Route
                path="/double-promotions/:id"
                name="Double Promotion Detail"
                element={<DoublePromotionDetail authed={true} />}
              />
              <Route
                path="/double-promotions/create"
                name="Create Double Promotion"
                element={
                  <Authorization>
                    <CreateDoublePromotion />
                  </Authorization>
                }
              />
              <Route
                path="/double-promotions/edit/:id"
                name="Edit Double Promotion"
                element={
                  <Authorization>
                    <EditDoublePromotion />
                  </Authorization>
                }
              />
              {/* promotion groups  */}
              <Route
                path="/promotion-groups/create"
                name="Create Promotion Group"
                element={<CreatePromotionGroup />}
              />
              <Route
                path="/promotion-groups/edit/:groupId"
                name="Edit Promotion Group"
                element={<EditPromotionGroup />}
              />
              <Route
                path="/promotion-groups/:groupId"
                name="Promotion Group Detail"
                element={<PromotionGroupDetail />}
              />
              <Route
                path="/promotion-groups"
                name="Promotion Group List"
                element={<PromotionGroupList />}
              />
              {/* gift routes  */}
              <Route path="/agents/gifts" name="Agent Gift List" element={<GiftList />} />
              <Route
                path="/agents/gifts/:id"
                name="Gift Detail"
                element={<GiftDetail authed={true} />}
              />
              <Route path="/agents/gifts/create" name="Agent Gifts" element={<CreateGift />} />
              <Route path="/agents/gifts/:id/edit" name="Agent Gifts" element={<EditGift />} />
              <Route path="/end-users/gifts" name="End User Gifts" element={<EndUserGiftList />} />
              <Route
                path="/end-users/gifts/:id"
                name="End User Gift Detail"
                element={<EndUserGiftDetail />}
              />
              <Route
                path="/end-users/gifts/:id/edit"
                name="End User Gift Detail"
                element={<EditEndUserGift />}
              />
              <Route
                path="/end-users/gifts/create"
                name="Create End User Gift"
                element={<CreateEndUserGift />}
              />
              {/* Lucky draw routes */}
              {/* Events */}
              <Route
                path="/lucky-draw/events"
                name="Lucky Draw Event List"
                element={<EventList />}
              />
              <Route
                path="/lucky-draw/event/:id"
                name="Lucky Draw Event Detail"
                element={<EventDetail />}
              />
              <Route
                path="/lucky-draw/event/create"
                name="Create Lucky Draw Event"
                element={<EventCreate />}
              />
              <Route
                path="/lucky-draw/event/:id/edit"
                name="Edit Lucky Draw Event"
                element={<EventEdit />}
              />
              {/* Prizes */}
              <Route
                path="/lucky-draw/prizes"
                name="Lucky Draw Prize List"
                element={<PrizeList />}
              />
              <Route
                path="/lucky-draw/prize/:id"
                name="Lucky Draw Prize Detail"
                element={<PrizeDetail />}
              />
              <Route
                path="/lucky-draw/prize/create"
                name="Create Lucky Draw Prize"
                element={<PrizeCreate />}
              />
              <Route
                path="/lucky-draw/prize/:id/edit"
                name="Edit Lucky Draw Prize"
                element={<PrizeEdit />}
              />
              {/* Prize Wineners */}
              <Route
                path="/lucky-draw/prize-winners"
                name="Prize Winner List"
                element={<PrizeWinnerList />}
              />
              {/* yearly top agents routes  */}
              <Route
                path="/yearly-top-agents"
                name="Yearly Top Agent List"
                element={<TopAgentList />}
              />
              <Route
                path="/yearly-top-agents/create"
                name="Create Yearly Top Agent"
                element={<CreateYearlyTopAgent />}
              />
              <Route
                path="/yearly-top-agents/edit/:id"
                name="Create Yearly Top Agent"
                element={<EditYearlyTopAgent />}
              />
              {/* monthly top agents routes  */}
              <Route
                path="/monthly-top-agents"
                name="Monthly Top Agent List"
                element={<MonthlyTopAgentList />}
              />
              <Route
                path="/monthly-top-agents/create"
                name="Create Monthly Top Agent"
                element={<CreateMonthlyTopAgent />}
              />
              <Route
                path="/Monthly-top-agents/edit/:id"
                name="Create Monthly Top Agent"
                element={<EditMonthlyTopAgent />}
              />
              {/* Global top agent */}
              <Route
                path="/global-top-agents"
                name="Globally Top Agent List"
                element={<GlobalTopAgentsList />}
              />
              <Route
                path="/global-top-agents/create"
                name="Create Global Top Agent"
                element={<CreateGlobalTopAgent />}
              />
              <Route
                path="/global-top-agents/edit/:id"
                name="Edit Global top agent"
                element={<EditGloballyTopAgent />}
              />
              {/* unicorn agent */}
              <Route
                path="/unicorn-agents"
                name="Unicorn Agent List"
                element={<UnicornAgentList />}
              />
              {/* agents */}
              <Route path="/agents" name="Agent List" element={<AgentList />} />
              <Route path="/agents/:id" name="Agent Detail" element={<AgentDetail />} />
              <Route path="/agents/create" name="Create Agent" element={<CreateAgent />} />
              <Route path="/agents/:id/edit" name="Edit Agent" element={<EditAgent />} />
              <Route
                path="/agents/:id/products"
                name="Agent Products"
                element={<AgentProductList />}
              />
              <Route
                path="/agents/:id/regions"
                name="Agent Regions"
                element={<AgentRegionList />}
              />
              <Route path="/agents/:id/gifts" name="Agent Gifts" element={<AgentGiftList />} />
              <Route path="/agents/:id/posts" name="Agent Posts" element={<AgentPostList />} />
              <Route
                path="/agents/:id/bonus-profile"
                name="Bonus Profile"
                element={<AgentBonusProfile />}
              />
              <Route
                path="/agents/:id/product-profile"
                name="Product Profile"
                element={<AgentProductProfile />}
              />
              <Route
                path="/agents/:id/posts/:postId"
                name="Agent Detail Post"
                element={<AgentDetailPost />}
              />
              <Route
                path="/agents/:id/regions/create"
                name="Create Agent Region"
                element={<CreateAgentRegion />}
              />
              <Route
                path="/agents/:id/regions/:regionId/edit"
                name="Edit Agent Region"
                element={<EditAgentRegion />}
              />
              <Route
                path="/agents/:id/products/create"
                name="Create Agent Product"
                element={<CreateAgentProduct />}
              />
              <Route
                path="/agents/:id/products/:agentProductId/edit"
                name="Edit Agent Product"
                element={<EditAgentProduct />}
              />
              <Route path="/histories" name="Agent Histories" element={<HistoryList />} />
              <Route path="/histories/agents" name="Agent Histories" element={<AgentHistory />} />
              <Route
                path="/histories/stocks"
                name="Agent Products Stock Histories"
                element={<StockHistory />}
              />
              <Route
                path="/agents/generate-reports"
                name="Generate Agent Report"
                element={<GenerateAgentReport />}
              />
              {/* Agent Bonus Stock */}
              <Route path="/agents/import-stock" name="Import Stock" element={<BonusStock />} />
              {/* registration  */}
              <Route
                path="/registrations"
                name="Registration List"
                element={<RegistrationList />}
              />
              <Route
                path="/registrations/create"
                name="Create Registration"
                element={<CreateRegistration />}
              />
              {/* national id  */}
              <Route path="/national-ids" name="National ID List" element={<NationalIdList />} />
              <Route
                path="/national-ids/:id"
                name="National ID Detail"
                element={<NationalIdDetail />}
              />
              <Route
                path="/national-ids/create"
                name="Create National ID"
                element={<CreateNationalId />}
              />
              <Route
                path="/national-ids/:id/edit"
                name="Edit National ID"
                element={<EditNationalId />}
              />
              {/* products  */}
              <Route path="/products" name="Product List" element={<ProductList />} />
              <Route path="/products/:id" name="Product Detail" element={<ProductDetail />} />
              <Route
                path="/products/create"
                name="Create Product"
                element={
                  <Authorization>
                    <CreateProduct />
                  </Authorization>
                }
              />
              <Route
                path="/products/:id/edit"
                name="Edit Product"
                element={
                  <Authorization>
                    <EditProduct />
                  </Authorization>
                }
              />
              {/* product flyers */}
              <Route
                path="/product-flyers"
                name="Product Flyer List"
                element={<ProductFlyerList />}
              />
              <Route
                path="/products/:productId/flyers"
                name="Product Flyer Detail"
                element={<ProductFlyerDetail />}
              />
              {/* prouct catalogues */}
              <Route
                path="/product-catalogues"
                name="Product Catalogues"
                element={<ProductCatalogueList />}
              />
              <Route
                path="/product-catalogues/create"
                name="Create Product Catalogue"
                element={<CreateProductCatalogue />}
              />
              <Route
                path="/products/:productId/:catalogueType/catalogues"
                name="Product Catalogue Detail"
                element={<ProductCatalogueDetail />}
              />
              <Route
                path="/products/:productId/:catalogueType/catalogues/edit"
                name="Edit Product Catalogue"
                element={<EditProductCatalogue />}
              />
              {/** Trip Product Groups */}
              <Route path="/product-groups" name="Product Group List" element={<GroupList />} />
              <Route
                path="/product-groups/:groupId"
                name="Product Group Detail"
                element={<GroupDetail />}
              />
              <Route
                path="/product-groups/create"
                name="Create Product Group"
                element={
                  <Authorization>
                    <CreateGroup />
                  </Authorization>
                }
              />
              <Route
                path="/product-groups/:groupId/edit"
                name="Edit Product Group"
                element={
                  <Authorization>
                    <EditGroup />
                  </Authorization>
                }
              />
              {/**Three Months Trip Product Groups Bonus Rules */}
              <Route
                path="/product-groups/:groupId/bonus-rules"
                name="Three Months Trip Bonus Rules List"
                element={<TriBonusRulesList />}
              />
              <Route
                path="/product-groups/:groupId/bonus-rules/create"
                name="Create Three Months Trip Bonus Rules"
                element={
                  <Authorization>
                    <CreateTriBonusRules />
                  </Authorization>
                }
              />
              <Route
                path="/product-groups/:groupId/bonus-rules/:ruleId/edit"
                name="Edit Three Months Trip Bonus Rules"
                element={
                  <Authorization>
                    <EditTriBonusRules />
                  </Authorization>
                }
              />
              {/* product attributes */}
              <Route
                path="/products/:id/attributes"
                name="Product Attribute"
                element={<ProductAttributeList />}
              />
              <Route
                path="/products/:id/attributes/:attributeId"
                name="Product Attribute Detail"
                element={<ProductAttributeDetail />}
              />
              <Route
                path="/products/:id/attributes/create"
                name="Create Product Attribute"
                element={
                  <Authorization>
                    <CreateProductAttribute />
                  </Authorization>
                }
              />
              <Route
                path="/products/:id/attributes/:attributeId/edit"
                name="Edit Product Attribute"
                element={
                  <Authorization>
                    <EditProductAttribute />
                  </Authorization>
                }
              />
              {/* product reviews */}
              <Route
                path="/products/:id/reviews"
                name="Product Reviews"
                element={<ProductReviewList />}
              />
              <Route
                path="/products/:id/reviews/:reviewId"
                name="Product Review Detail"
                element={<ProductReviewDetail />}
              />
              <Route
                path="/products/:id/reviews/create"
                name="Create Product Review"
                element={<CreateProductReview />}
              />
              <Route
                path="/products/:id/reviews/:reviewId/edit"
                name="Edit Product Review"
                element={<EditProductReview />}
              />
              {/* product posts */}
              <Route
                path="/products/:id/posts"
                name="Product Posts"
                element={<ProductPostList />}
              />
              <Route
                path="/products/:id/posts/create"
                name="Create Product Review"
                element={
                  <Authorization>
                    <CreateProductPost />
                  </Authorization>
                }
              />
              <Route
                path="/products/:id/posts/:postId"
                name="Product Post Detail"
                element={<ProductPostDetail />}
              />
              <Route
                path="/products/:id/posts/:postId/edit"
                name="Edit Product Post"
                element={
                  <Authorization>
                    <EditProductPost />
                  </Authorization>
                }
              />
              {/* product sale promotion*/}
              <Route
                path="/products/:id/sale-promotions"
                name="Product End User Promotion"
                element={<ProductSalePromotionList />}
              />
              <Route
                path="/products/:id/sale-promotions/create"
                name="Create End User Promotion"
                element={
                  <Authorization>
                    <CreateProductSalePromotion />
                  </Authorization>
                }
              />
              <Route
                path="/products/:id/sale-promotions/:promoId/edit"
                name="Edit End User Promotion"
                element={
                  <Authorization>
                    <EditProductSalePromotion />
                  </Authorization>
                }
              />
              {/* product monthly promotion*/}
              <Route
                path="/products/:id/monthly-promotion-group"
                name="Product Monthly Promotion"
                element={<ProductMonthlyPromotion />}
              />
              <Route
                path="/products/:id/monthly-promotion-group/create"
                name="Product Monthly Promotion"
                element={
                  <Authorization>
                    <CreateProductMonthlyPromotion />
                  </Authorization>
                }
              />
              <Route
                path="/products/:id/monthly-promotion-group/:promoId/edit"
                name="Edit Product Monthly Promotion"
                element={
                  <Authorization>
                    <EditProductMonthlyPromotion />
                  </Authorization>
                }
              />
              {/* product FOC promotion*/}
              <Route
                path="/products/:id/foc-promotions"
                name="Product FOC Promotion"
                element={<ProductFOCPromotion />}
              />
              <Route
                path="/products/:id/foc-promotions/create"
                name="Product FOC Promotion"
                element={<CreateProductFOC />}
              />
              <Route
                path="/products/:id/foc-promotions/:promoId/edit"
                name="Edit Product FOC Promotion"
                element={
                  <Authorization>
                    <EditProductFOCPromotion />
                  </Authorization>
                }
              />
              {/* product discount promotion*/}
              <Route
                path="/products/:id/discount-promotions"
                name="Product Discount Promotion"
                element={<ProductDiscountPromotion />}
              />
              <Route
                path="/products/:id/discount-promotions/create"
                name="Create Product Discount Promotion"
                element={
                  <Authorization>
                    <CreateProductDiscount />
                  </Authorization>
                }
              />
              <Route
                path="/products/:id/discount-promotions/:promoId/edit"
                name="Edit Product Discount Promotion"
                element={
                  <Authorization>
                    <EditProductDiscount />
                  </Authorization>
                }
              />
              {/* prduct monthly bonus rules list  */}
              <Route
                path="/products/:id/monthly-bonus-rules"
                name="Product Bonus Rules List"
                element={<ProductMonthlyBonusRule />}
              />
              <Route
                path="/products/:id/monthly-bonus-rules/create"
                name="Product Monthly Bonus Rule Create"
                element={
                  <Authorization>
                    <CreateProductMonthlyBonusRule />
                  </Authorization>
                }
              />
              <Route
                path="/products/:id/monthly-bonus-rules/:ruleId/edit"
                name="Edit Product Monthly Bonus Rules"
                element={
                  <Authorization>
                    <EditProductMonthlyBonusRule />
                  </Authorization>
                }
              />
              {/* member types routes  */}
              <Route path="/member-types" name="Member Type List" element={<MemberTypeList />} />
              <Route
                path="/member-types/:id"
                name="Member Type Detail"
                element={<MemberTypeDetail authed={true} />}
              />
              <Route
                path="/member-types/create"
                name="Create Member Type"
                element={<CreateMemberType />}
              />
              <Route
                path="/member-types/:id/edit"
                name="Edit Member Type"
                element={<EditMemberType />}
              />
              {/* inventories routes  */}
              <Route path="/inventories" name="Inventory List" element={<InventoryList />} />
              <Route
                path="/inventories/history"
                name="Inventory History List"
                element={<InventoryHistoryList />}
              />
              <Route
                path="/inventories/create"
                name="Create Inventory"
                element={<CreateInventory />}
              />
              {/* currencies  */}
              <Route path="/currencies" name="Currency List" element={<CurrencyList />} />
              <Route path="/currencies/:id" name="Currency Detail" element={<CurrencyDetail />} />
              <Route
                path="/currencies/create"
                name="Create Currency"
                element={<CreateCurrency />}
              />
              <Route path="/currencies/edit/:id" name="Edit Currency" element={<EditCurrency />} />
              {/* currency history  */}
              <Route
                path="/currency-histories"
                name="Currency History List"
                element={<CurrencyHistoryList />}
              />
              {/* currency region  */}
              <Route
                path="/currency-regions"
                name="Currency Region List"
                element={<CurrencyRegionList />}
              />
              <Route
                path="/currency-regions/:id"
                name="Currency Region Detail"
                element={<CurrencyRegionDetail />}
              />
              <Route
                path="/currency-regions/create"
                name="Create Currency Region"
                element={<CreateCurrencyRegion />}
              />
              <Route
                path="/currency-regions/edit/:id"
                name="Edit Currency Region"
                element={<EditCurrencyRegion />}
              />
              {/* Bonus Profile */}
              <Route
                path="/bonus-profile"
                name="Bonus Profile List"
                element={<BonusProfileList />}
              />
              <Route
                path="/bonus-profile/:id/upload"
                name="Bonus Profile Upload"
                element={
                  <Authorization>
                    <BonusProfileUpload />
                  </Authorization>
                }
              />
              <Route
                path="/bonus-profile/create"
                name="Bonus Profile List"
                element={
                  <Authorization>
                    <CreateBonusProfile />
                  </Authorization>
                }
              />
              <Route
                path="/product-profile"
                name="Product Profile Upload"
                element={<ProductProfileList />}
              />
              <Route
                path="/product-profile/create"
                name="Product Profile Create"
                element={
                  <Authorization>
                    <CreateProductProfile />
                  </Authorization>
                }
              />
              <Route
                path="/product-profile/:id/upload"
                name="Product Profile Upload"
                element={
                  <Authorization>
                    <ProductProfileUpload />
                  </Authorization>
                }
              />
              {/** Bonus Groups */}
              <Route path="/bonus-groups" name="Bonus Group List" element={<BonusGroupList />} />
              <Route
                path="/bonus-groups/:groupId"
                name="Bonus Group Detail"
                element={<BonusGroupDetail />}
              />
              <Route
                path="/bonus-groups/create"
                name="Create Bonus Group"
                element={
                  <Authorization>
                    <CreateBonusGroup />
                  </Authorization>
                }
              />
              <Route
                path="/bonus-groups/:groupId/edit"
                name="Edit Bonus Group"
                element={
                  <Authorization>
                    <EditBonusGroup />
                  </Authorization>
                }
              />
              {/* bonus frame routes  */}
              <Route
                path="/monthly/bonus-frames"
                name="Bonus Frame List"
                element={<BonusFrameList />}
              />
              <Route
                path="/year-end/bonus-frames"
                name="Year End Frames"
                element={<YearEndFrameList />}
              />
              <Route
                path="/three-months/bonus-frames"
                name="Three Month Frame"
                element={<ThreeMonthFrameList />}
              />
              <Route
                path="/six-months/bonus-frames"
                name="Six Month Frame"
                element={<SixMonthFrameList />}
              />
              <Route
                path="/bonus-frames/:frameId"
                name="Detailed Bonus Frame List"
                element={<BonusFrameDetail />}
              />
              <Route
                path="/bonus-frames/all-frame/create"
                name="Create Bonus Frame"
                element={
                  <Authorization>
                    <CreateBonusFrame />
                  </Authorization>
                }
              />
              <Route
                path="/bonus-frames/product-frame/create"
                name="Create Product Bonus Frame"
                element={
                  <Authorization>
                    <CreateProductFrame />
                  </Authorization>
                }
              />
              <Route
                path="/year-end/bonus-frames/create"
                name="Create Year End Frame"
                element={
                  <Authorization>
                    <CreateYearEndFrame />
                  </Authorization>
                }
              />
              <Route
                path="/bonus-frames/:frameId/edit"
                name="Edit Bonus Frame"
                element={
                  <Authorization>
                    <EditBonusFrame />
                  </Authorization>
                }
              />

              <Route
                path="/year-end/bonus-frames/:frameId/edit"
                name="Edit Year End Frame"
                element={
                  <Authorization>
                    <EditYearEndFrame />
                  </Authorization>
                }
              />
              {/* bonus award routes */}
              <Route path="/bonus-awards" name="Bonus Award List" element={<AllBonusAwardList />} />
              <Route
                path="/bonus-awards/:awardId"
                name="Bonus Award Products"
                element={<AllBonusAwardDetail />}
              />
              <Route
                path="/bonus-awards-details"
                name="Bonus Award Detail"
                element={<ProductBonusAwardList />}
              />
              {/* total bonus award routes */}
              <Route
                path="/total-bonus-awards"
                name="Total Bonus Award List"
                element={<TotalBonusAwardList />}
              />
              {/* bonus rule */}
              <Route
                path="/monthly-bonus-rules"
                name="Bonus Rule List"
                element={<BonusRuleListPage />}
              />
              <Route
                path="/monthly-bonus-rules/create"
                name="Create Bonus Rule"
                element={
                  <Authorization>
                    <CreateMonthlyBonusRule />
                  </Authorization>
                }
              />
              <Route
                path="/monthly-bonus-rules/:ruleId"
                name="Bonus Rule Detail"
                element={<MonthlyBonusRuleDetail />}
              />
              <Route
                path="/monthly-bonus-rules/edit/:ruleId"
                name="Edit Bonus Rule"
                element={
                  <Authorization>
                    <EditMonthlyBonusRule />
                  </Authorization>
                }
              />
              <Route
                path="/users"
                element={
                  <Authorization>
                    <UserList />
                  </Authorization>
                }
                name="User list"
              />
              <Route path="/users/create" element={<CreateUser />} name="Create User" />
              <Route path="/users/:userId" element={<UserDetail />} name="Detail User" />
              <Route path="/users/edit/:userId" element={<EditUser />} name="Update User" />
              {/* three months bonus rule */}
              <Route
                path="/tri-bonus-rules"
                name="Create Bonus Rule List"
                element={<BonusRuleListPage />}
              />
              <Route
                path="/tri-bonus-rules/create"
                name="Create Three Months Bonus Rule"
                element={
                  <Authorization>
                    <CreateThreeMonthsBonusRule />
                  </Authorization>
                }
              />
              <Route
                path="/tri-bonus-rules/:ruleId"
                name="Three Months Bonus Rule Detail"
                element={<ThreeMonthsBonusRuleDetail />}
              />
              <Route
                path="/tri-bonus-rules/:ruleId/edit"
                name="Update Three Months Bonus Rule"
                element={
                  <Authorization>
                    <EditThreeMonthsBonusRule />
                  </Authorization>
                }
              />
              {/* trips certificate */}
              <Route
                path="/three-months-trips"
                name="Trips Certificate List"
                element={<TripList />}
              />
              <Route
                path="/three-months-trips/:tripId"
                name="Trips Certificate Detail"
                element={<TripDetail />}
              />
              <Route
                path="/three-months-trips/product-groups"
                name="Trip Product Group"
                element={<TripProductGroup />}
              />
              {/* trips reports */}
              <Route
                path="/three-months-trips-reports"
                name="Trips Report List"
                element={<TripReportList />}
              />
              <Route
                path="/three-months-trips-reports/:id"
                name="Trips Report List Detail"
                element={<TripReportListDetail />}
              />
              <Route
                path="/three-months-trips-reports/product-groups"
                name="Trip Report Product Group"
                element={<TripReportProductGroup />}
              />
              <Route
                path="/three-months-trips-reports/product-groups/:id"
                name="Trip Report Product Group Detail"
                element={<TripReportProductGroupDetail />}
              />
              {/* six months bonus */}
              <Route
                path="/six-months-bonus"
                name="Six Months All Frame Certificate"
                element={<SixMonthsBonusList />}
              />
              <Route
                path="/six-months-bonus/:bonusID"
                name="Six Months Bonus Detail"
                element={<SixMonthsBonusDetail />}
              />
              {/**bonus prizes */}
              <Route path="/bonus-prizes" name="Bonus Prize List" element={<BonusPrizeList />} />
              <Route
                path="/bonus-prizes/create"
                name="Bonus Prize Create"
                element={
                  <Authorization>
                    <CreateBonusPrize />
                  </Authorization>
                }
              />
              <Route
                path="/bonus-prizes/:prizeId"
                name="Bonus Prize Detail"
                element={<BonusPrizeDetail />}
              />
              <Route
                path="/bonus-prizes/:prizeId/edit"
                name="Bonus Prize Detail"
                element={
                  <Authorization>
                    <EditBonusPrize />
                  </Authorization>
                }
              />
              {/* /** Notifications */}
              <Route
                path="/notifications"
                name="Notification List"
                element={<NotificationList />}
              />
              <Route
                path="/notifications/:notificationId"
                name="Notification Detail"
                element={<NotificationDetail />}
              />
              <Route
                path="/notifications/create"
                name="Create Notification"
                element={<CreateNotification />}
              />
              {/* /** Notifications */}
              <Route path="/surveys" name="Survey Form List" element={<SurveyList />} />
              <Route
                path="/surveys/:formId/responses/summary"
                name="Survey Form Detail"
                element={<SurveyResponse />}
              />
              <Route
                path="/surveys/:formId/responses/question"
                name="Survey Form Question"
                element={<SurveyResponse />}
              />
              <Route
                path="/surveys/:formId/responses/individual"
                name="Survey Form Individual"
                element={<SurveyResponse />}
              />
              <Route path="/surveys/create" name="Create Survey Form" element={<CreateSurvey />} />
              {/* six month trips */}
              <Route
                path="/six-months-trips"
                element={<SixMonthTripList />}
                name="Six Months Trip List"
              />
              <Route
                path="/six-months-trips/product-groups"
                element={<SixMonthTripDetail />}
                name="Six Months Trips Detail"
              />
              {/* year end reports */}
              <Route
                path="/year-end-reports"
                name="Year End Report"
                element={<YearEndReportList />}
              />
              <Route
                path="/year-end-reports/:id"
                name="Year End Report Detail"
                element={<YearEndReportDetail />}
              />
              <Route
                path="/year-end-reports/products"
                name="Year End Report Product Group"
                element={<YearEndReportGroup />}
              />
              {/* year end award */}
              <Route
                path="/year-end-awards"
                name="Year End Awards"
                element={<YearEndAwardList />}
              />
              <Route
                path="/year-end-awards/:id"
                name="Year End Award Detail"
                element={<YearEndAwardDetail />}
              />
              <Route
                path="/year-end-awards/product-groups"
                name="Year End Award Product Group"
                element={<YearEndAwardProductGroup />}
              />
              {/* year end bonus rule */}
              <Route
                path="/year-end-rules"
                name="Year End Rule List"
                element={<BonusRuleListPage />}
              />
              <Route
                path="/year-end-rules/create"
                name="Create Year End Rule"
                element={
                  <Authorization>
                    <CreateYearEndRule />
                  </Authorization>
                }
              />
              <Route
                path="/year-end-rules/:ruleId"
                name="Year End Rule Detail"
                element={<YearEndRuleDetail />}
              />
              <Route
                path="/year-end-rules/:ruleId/edit"
                name="Edit Year End Bonus Rule"
                element={
                  <Authorization>
                    <EditYearEndRule />
                  </Authorization>
                }
              />

              {/* birthday frame */}
              <Route
                path="/birthday-agents"
                name="Birthday Agents"
                element={<BirthdayAgentList />}
              />
              <Route
                path="/birthday-frames"
                name="Birthday Frame List"
                element={<BirthdayFrameList />}
              />
              <Route
                path="/birthday-frames/:birthday_frame_id"
                name="Birthday Frame Detail"
                element={<BirthdayFrameDetail />}
              />
              <Route
                path="/birthday-frames/:levelId/create"
                name="Create Birthday Frame"
                element={<CreateBirthdayFrame />}
              />

              <Route
                path="birthday-frames/:birthday_frame_id/edit"
                name="Edit Birthday Frame"
                element={<EditBirthdayFrame />}
              />

              {/* qr code file upload */}
              <Route path="/files" name="QR Code File List" element={<QRCodeFileList />} />
              <Route
                path="/files/upload-file"
                name="Create QR Code File"
                element={<CreateQRCodeFile />}
              />

              {/* invoice theme  */}
              <Route path="/invoice-themes" name="Invoice Themes" element={<InvoiceThemeList />} />
              <Route
                path="/invoice-themes/:theme_id"
                name="Invoice Theme Detail"
                element={<InvoiceThemeDetail />}
              />
              <Route
                path="/invoice-themes/create"
                name="Create Invoice Theme"
                element={<CreateInvoiceTheme />}
              />
              <Route
                path="/invoice-themes/:theme_id/edit"
                name="Invoice Theme Edit"
                element={<EditInvoiceTheme />}
              />
              {/* Agent Levels */}
              <Route
                path="/agents-with-levels"
                name="Agent Level Agent List"
                element={<AgentLevelAgentList />}
              />
              <Route path="/agent-levels" name="Agent Level List" element={<AgentLevelList />} />
              <Route
                path="/agent-levels/create"
                name="Create Agent Level"
                element={<CreateAgentLevel />}
              />
              <Route
                path="/agent-levels/:levelId/edit"
                name="Edit Agent Level"
                element={<EditAgentLevel />}
              />
              <Route
                path="/agent-levels/:levelId"
                name="Agent Level Detail"
                element={<AgentLevelDetail />}
              />
              <Route
                path="/year-end-trips/product-groups"
                name="Year End Trip Groups"
                element={<YearEndTripGroup />}
              />

              {/* year end group awards */}
              <Route
                path="/year-end-group-awards"
                name="Year End Group Awards"
                element={<YearEndGroupAwardList />}
              />
              <Route
                path="/year-end-group-awards/:awardId"
                name="Year End Group Award Detail"
                element={<YearEndGroupAwardDetail />}
              />
              <Route
                path="/year-end-group-awards/create"
                name="Create Year End Group Award"
                element={
                  <Authorization>
                    <CreateYearEndGroupAward />
                  </Authorization>
                }
              />
              <Route
                path="/year-end-group-awards/:awardId/edit"
                name="Edit Year End Group Award"
                element={
                  <Authorization>
                    <EditYearEndGroupAward />
                  </Authorization>
                }
              />
              {/* year end bonus awards */}
              <Route
                path="/year-end-bonus-awards"
                name="Year End Bonus Awards"
                element={<YearEndBonusAwardList />}
              />
              <Route
                path="/year-end-bonus-awards/:id"
                name="Year End Bonus Awards Detail"
                element={<YearEndBonusAwardDetail />}
              />

              <Route
                path="/year-end-bonus-awards/:id/child"
                name="Year End Bonus Awards Child List"
                element={<YearEndBonusAwardChildList />}
              />

              <Route
                path="/year-end-bonus-awards/product-groups"
                name="Year End Award Product Group"
                element={<YearEndBonusAwardProductGroup />}
              />
            </Route>
          </>
        )}
      </Routes>
    </>
  )
}

export default Router
