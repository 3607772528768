import {
  CButton,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CDropdown,
  CCard,
  CCardBody,
} from '@coreui/react'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { Link } from 'react-router-dom'
import './YearEndFrameList.css'
import BonusFrameTabs from 'src/components/BonusFrames/BonusFrameTabs'
import { YearEndFrameFilter } from 'src/components/BonusFrames/YearEnd/YearEndFrameFilter'
import { YearEndFrameTable } from 'src/components/BonusFrames/YearEnd/YearEndFrameTable'
import CommonBreadcrumb from 'src/components/Common/CommonBreadcrumb'
import TabPanel from 'src/components/Material/TabPanel'
import TotalCount from 'src/components/TotalCount/TotalCount'
import { BonusTypeEnum, FrameCategoryTypeEnum } from 'src/configs/EnumData'
import useAxios from 'src/hooks/useAxios'
import API from 'src/network/API'
import { paginateIteration } from 'src/utils'
import PreviewModal from 'src/components/Uploads/PreviewModal/PreviewModal'
import styles from 'src/components/Uploads/PreviewModal/PreviewModal'
import UserPermission from 'src/components/Common/UserPermission'

const ROW_COUNT = 5
export const YearEndFrameList = () => {
  const { instance } = useAxios()

  const [paginateIndex, setPaginateIndex] = useState(0)
  const [pagination, setPagination] = useState({ currentPage: 1, totalCount: 0, totalPage: 0 })
  const [bonusFrames, setBonusFrames] = useState([])
  const [payload, setPayload] = useState({
    bonus_type_id: BonusTypeEnum.YearEnd,
    product_id: null,
    sale_region: '',
    category: '',
  })
  const [loading, setLoading] = useState(false)
  const [activeKey, setActiveKey] = useState(3)
  const [show, setShow] = useState(false)
  const [selectedImage, setSelectedImage] = useState('')

  const fetchBonusFrame = async (currentPage = 1) => {
    setLoading(true)
    const url = `${API.frameList}`

    const response = await instance
      .post(url, {
        page: currentPage ?? 1,
        row_count: ROW_COUNT,
        ...payload,
      })
      .catch((error) => {
        setLoading(false)
        const errors = error?.response?.data
        const { code, message } = errors

        if (code === 400) {
          Object.keys(message)?.map((key) =>
            toast.error(message[key], {
              position: 'top-right',
            }),
          )
        }
      })

    setLoading(false)
    if (response) {
      const { data } = response

      setPagination({
        ...pagination,
        currentPage: data?.pagination?.page,
        totalCount: data?.pagination?.total_records_count,
        totalPage: data?.pagination.total_page,
      })
      setBonusFrames(data?.data)
      setPaginateIndex(paginateIteration(data?.data, data?.pagination.page, ROW_COUNT))
    }
  }

  useEffect(() => {
    fetchBonusFrame()
  }, [payload])

  const handlePage = (page) => {
    setPagination({
      ...pagination,
      currentPage: page,
    })
    fetchBonusFrame(page)
  }

  const handleDelete = (frameId) => {
    const url = `${API.deleteFrame}`
    instance
      .post(url, {
        bonus_frame_id: frameId,
      })
      .then((response) => {
        toast.success('Bonus Frame Deleted Successfully!', {
          position: 'top-right',
        })
        setBonusFrames(bonusFrames?.filter((frame) => frame?.id !== frameId))
        setPagination({
          ...pagination,
          totalCount: pagination?.totalCount - 1,
        })
      })
      .catch((error) => {
        const errors = error?.response?.data
        const { code, message } = errors
        if (code === 400) {
          Object.keys(message)?.map((key) =>
            toast.error(message[key], {
              position: 'top-right',
            }),
          )
        }
      })
  }

  const openModal = (path) => {
    setShow(true)
    setSelectedImage(path)
  }

  return (
    <>
      <CommonBreadcrumb name="Year End Frame" items={[{ name: 'Year End Frames' }]} />
      <CCard>
        <CCardBody>
          <UserPermission>
            {/* <div className="card_container">
              <Link to="/year-end/bonus-frames/create">
                <CButton size="sm" className="card_container__button">
                  <FontAwesomeIcon icon={faPlus} color="#fff" /> Create Frame
                </CButton>
              </Link>
            </div> */}
            <div className="bonus_frame_container">
              <CDropdown variant="btn-group">
                <CDropdownToggle className="bonus_frame_container__button">
                  New Year End Frame
                </CDropdownToggle>
                <CDropdownMenu className="bonus_frame_dropdown_container">
                  <CDropdownItem
                    className="bonus_frame_dropdown_menu"
                    href={`/bonus-frames/all-frame/create?bonus_type=year-end`}
                  >
                    New All Frame
                  </CDropdownItem>
                  <CDropdownItem
                    className="bonus_frame_dropdown_menu"
                    href={`/year-end/bonus-frames/create`}
                  >
                    New Product Frame
                  </CDropdownItem>
                </CDropdownMenu>
              </CDropdown>
            </div>
          </UserPermission>
          <div className="mb-4">
            <BonusFrameTabs activeKey={activeKey} onTabChange={(value) => setActiveKey(value)} />
          </div>
          <TabPanel value={activeKey} index={3}>
            <YearEndFrameFilter payload={payload} setPayload={setPayload} />
            {pagination?.totalCount > 0 && <TotalCount count={pagination?.totalCount} />}
            <YearEndFrameTable
              paginateIndex={paginateIndex}
              totalCount={pagination?.totalCount}
              totalPage={pagination?.totalPage}
              currentPage={pagination?.currentPage}
              bonusFrames={bonusFrames}
              handlePage={handlePage}
              handleBonusFrame={handleDelete}
              loading={loading}
              openModal={openModal}
            />
            {show && (
              <PreviewModal show={show} onClose={() => setShow(false)}>
                <div className="content">
                  {selectedImage && (
                    <>
                      <img
                        width={'500px'}
                        src={selectedImage}
                        alt="preview img"
                        className={styles.image_full_screen}
                      />
                    </>
                  )}
                </div>
              </PreviewModal>
            )}
          </TabPanel>
        </CCardBody>
      </CCard>
    </>
  )
}
