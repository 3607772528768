import { CTableDataCell, CTableRow } from '@coreui/react'
import { faEdit, faEye, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Modal from 'src/components/Modal'
import PropTypes from 'prop-types'
import { localeName } from 'src/utils'
import FallbackImage from 'src/components/FallbackImage'
import UserPermission from 'src/components/Common/UserPermission'

export const YearEndFrameTableRow = (props) => {
  const { paginateIndex, index, bonusFrame, handleBonusFrame, openModal } = props
  const [visible, setVisible] = useState(false)

  const clickHandler = () => {
    handleBonusFrame(bonusFrame?.id)
  }

  return (
    <CTableRow>
      <CTableDataCell className="text-center">{paginateIndex + index}</CTableDataCell>
      <CTableDataCell className="text-center">
        {bonusFrame?.path && (
          <div className="image_path">
            <FallbackImage
              path={bonusFrame?.path}
              title="year-end-frame"
              openModal={() => openModal(bonusFrame?.path)}
            />
            {/* <img src={bonusFrame?.path} alt="year_end_frame" loading="lazy" /> */}
          </div>
        )}
      </CTableDataCell>
      <CTableDataCell className="text-center">
        {bonusFrame?.group?.name}
        {bonusFrame?.group?.sale_region_id !== null &&
          bonusFrame?.group &&
          ` (${localeName(bonusFrame?.group?.sale_region_id)}) `}
      </CTableDataCell>
      <CTableDataCell className="text-center">{bonusFrame?.all_frame_type ?? `-`}</CTableDataCell>
      <CTableDataCell className="text-center">{bonusFrame?.bonus_type}</CTableDataCell>
      <CTableDataCell className="text-center">{bonusFrame?.image_type}</CTableDataCell>
      <CTableDataCell className="text-center">{bonusFrame?.category}</CTableDataCell>
      <CTableDataCell className="text-center">{bonusFrame?.region_name}</CTableDataCell>
      <CTableDataCell className="text-center">
        <Link to={`/bonus-frames/${bonusFrame?.id}?bonus_type=year-end`}>
          <span title="Detail Bonus Frame" className="mx-2">
            <FontAwesomeIcon icon={faEye} color="rgb(0, 200, 83)" />
          </span>
        </Link>
        <UserPermission>
          <Link
            to={
              bonusFrame?.group
                ? `/year-end/bonus-frames/${bonusFrame?.id}/edit`
                : `/bonus-frames/${bonusFrame?.id}/edit`
            }
          >
            <span title="Edit Bonus Frame" className="mx-2">
              <FontAwesomeIcon icon={faEdit} color="rgb(33, 150, 243)" />
            </span>
          </Link>
        </UserPermission>
        <UserPermission>
          <span
            title="Delete Bonus Frame"
            className="mx-2"
            style={{ cursor: 'pointer' }}
            onClick={() => setVisible(!visible)}
          >
            <FontAwesomeIcon icon={faTrash} color="#FF4747" />
          </span>
        </UserPermission>
      </CTableDataCell>
      <Modal
        visible={visible}
        setVisible={setVisible}
        title="Delete Year End Frame"
        body={`Are you sure you want to delete ${bonusFrame?.name}`}
        clickHandler={clickHandler}
      />
    </CTableRow>
  )
}

YearEndFrameTableRow.propTypes = {
  paginateIndex: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  bonusFrame: PropTypes.object.isRequired,
  handleBonusFrame: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
}
