import React from 'react'
import PropTypes from 'prop-types'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'

const ErrorMessage = ({ error, showKey = false }) => {
  return (
    <Alert severity="error">
      <AlertTitle>Error</AlertTitle>
      {Object.keys(error).map((item, key) => (
        <option key={key} value={item}>
          {showKey ? `${item} &nbsp;` : ''}
          {error[item]}
        </option>
      ))}
    </Alert>
  )
}

ErrorMessage.propTypes = {
  error: PropTypes.object.isRequired,
  showKey: PropTypes.bool,
}

export default ErrorMessage
