import { combineReducers } from 'redux'
import authReducer from './auth'
import loadingReducer from './loading'
import messageReducer from './message'
import productReducer from './product'
import regionReducer from './region'
import sidebarReducer from './sidebar'
import allFrameBonusReducer from './allFrameBonus'
import mainAgentReducer from './mainAgent'
import bonusGenerateReducer from './bonusGenerate'
import productBonusReducer from './productBonus'
import bonusGroupReducer from './bonusGroup'
import tripProductGroupReducer from './tripProductBonus'
import questionTypeReducer from './questionType'
import allSixMonthsBonusReducer from './allSixMonthsBonus'
import sixMonthAllTripReducer from './sixMonthsTripBonus'
import notiExcludeAgentReducer from './notiExcludeAgent'
import infiniteScrollCountReducer from './infiniteScrollCount'
import productDetailReducer from './productDetail'
import productGroupDetailReducer from './productGroupDetail'
import productGroupReducer from './productGroup'
import reportRegionReducer from './reportRegion'
import buttonDisableReducer from './isButtonDisabled'
import agentLevelReducer from './agentLevel'
import bonusTypeReducer from './bonusType'
import yearEndTripGroupReducer from './yearEndTripGroupBonus'
import yearEndAwardGroupReducer from './yearEndAwardGroupBonus'
import stockTypeReducer from './stockType'
import agentBirthdayReducer from './agentBirthday'
import eventreducers from './event'
import luckyDrawPrizeReducer from './luckyDrawPrize'
import yearEndAllReducer from './yearEndAllFrames'

const rootReducers = combineReducers({
  auth: authReducer,
  message: messageReducer,
  sidebar: sidebarReducer,
  loading: loadingReducer,
  regions: regionReducer,
  products: productReducer,
  allFrameBonus: allFrameBonusReducer,
  mainAgent: mainAgentReducer,
  generateBonus: bonusGenerateReducer,
  productBonus: productBonusReducer,
  bonusGroup: bonusGroupReducer,
  tipProductBonus: tripProductGroupReducer,
  questionType: questionTypeReducer,
  allSixMonthsBonus: allSixMonthsBonusReducer,
  allSixMonthsTrips: sixMonthAllTripReducer,
  notiExcludeAgents: notiExcludeAgentReducer,
  infiniteScrollCount: infiniteScrollCountReducer,
  productDetail: productDetailReducer,
  productGroupDetail: productGroupDetailReducer,
  productGroups: productGroupReducer,
  reportRegions: reportRegionReducer,
  buttonDisable: buttonDisableReducer,
  agentLevel: agentLevelReducer,
  bonusTypes: bonusTypeReducer,
  yearEndTripGroups: yearEndTripGroupReducer,
  yearEndAwardGroups: yearEndAwardGroupReducer,
  types: stockTypeReducer,
  allAgentBirthdays: agentBirthdayReducer,
  events: eventreducers,
  luckyDrawPrizes: luckyDrawPrizeReducer,
  yearEndAllFrame: yearEndAllReducer,
})

export default rootReducers
