import React from 'react'

const CreateTransaction = React.lazy(() =>
  import('../pages/Transactions/CreateTransaction/CreateTransaction'),
)

const TransactionList = React.lazy(() =>
  import('../pages/Transactions/TransactionList/TransactionList'),
)
const TransactionDetail = React.lazy(() =>
  import('../pages/Transactions/TransactionDetail/TransactionDetail'),
)

const SmartSaleTransactionList = React.lazy(() =>
  import('../pages/Transactions/SmartSaleTransaction/SmartSaleTransactionList'),
)

const EndUsersTransactionList = React.lazy(() =>
  import('../pages/Transactions/EndUsersTransactionList/EndUsersTransactionList'),
)

const OverallTransactionList = React.lazy(() =>
  import('../pages/Transactions/OverallTransactionList/OverallTransactionList'),
)

const DesignatedAgentsTransactionList = React.lazy(() =>
  import('../pages/Transactions/DesignatedAgentsTransactionList/DesignatedAgentsTransactionList'),
)

const EndUserGiftVoucherList = React.lazy(() =>
  import('../pages/Transactions/EnduserGiftVoucherList/EnduserGiftVoucherList'),
)

export {
  CreateTransaction,
  TransactionList,
  TransactionDetail,
  SmartSaleTransactionList,
  EndUsersTransactionList,
  OverallTransactionList,
  DesignatedAgentsTransactionList,
  EndUserGiftVoucherList,
}
